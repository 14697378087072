import { createPinia, defineStore, PiniaPluginContext } from "pinia";
import { useApiStore } from "./api";

export const pinia = createPinia();

pinia
  .use(({ app, store }: PiniaPluginContext) => {
    store.apiClient = app.config.globalProperties.$apiClient;
  });

export const useRootStore = defineStore("RootStore", {
  actions: {
    async init() {
      const api = useApiStore();

      await api.init();
    },
  },
});