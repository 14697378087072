import openAPIClientAxios from "./plugins/openAPIClientAxios";
import { AxiosError } from "axios";
import { createApp } from "vue";
import IssuerApp from "./IssuerApp.vue";
import router from "./router/issuer";
import { pinia, useRootStore } from "./stores/issuer";
import dayjsPlugin from "./plugins/dayjs";
import PrimeVue from "primevue/config";
import ToastService from "primevue/toastservice";
import { VueSvgIconPlugin } from "@yzfe/vue3-svgicon";
import pageTitleMixin from "@/mixins/pageTitle";

import "@yzfe/svgicon/lib/svgicon.css";
import "./assets/scss/issuer-homepage/main.scss";

const app = createApp(IssuerApp)
  .use(VueSvgIconPlugin, { tagName: "icon" })
  .use(openAPIClientAxios)
  .use(pinia)
  .use(dayjsPlugin)
  .use(PrimeVue, {
    theme: {
      options: {
        cssLayer: {
          name: "primevue",
        },
      },
    },
  })
  .use(ToastService)
  .mixin(pageTitleMixin);

  const rootStore = useRootStore();
  rootStore
    .init()
    .then(() => {
      // because we need to first load the root store and only then load the router
      // this prevent race conditions where router checks for authentication before
      // the accountStore has time to get the user details
      app.use(router);
  
      app.mount("#app");
    })
    .catch(e => {
      // normalize the exception value to make sure it's an Error object
      if (!(e instanceof Error)) {
        e = new Error(e);
      }
  
      if (e.name === "AxiosError") {
        const axiosError: AxiosError = e as AxiosError;
        if (axiosError.response?.status) {
          window.location.href = `/app-down.html?status=${axiosError.response?.status}`;
        }
  
        window.location.href = `/app-down.html`;
      }
    });
